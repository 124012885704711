<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <Card :title="'Edit Pasien'">
          <template v-slot:body>
            <div v-if="dataLoaded == true">

              <Form
                :form="form"
                :route="'patients/'+ form.id"
                purpose="master"
                :password='false'
                :edit='true'
              />

            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import Form from '@/component/patients/Form.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import module from '@/core/modules/CrudModule.js'

export default {

  name: 'Edit',

  data() {
    return {
      form: {
        name: "",
        patient_id: "",
        email: "",
        birt_place: "",
        birt_date: "",
        address: "",
        gender: "",
        phone: "",
        mobile_phone: "",
        parent_name: "",
        blood_type: "",
        id_card_number: "",
        allergy: "",
        photo: [],
        photo_default: "/assets/public/default-profile.svg",
        doctor_in_charge: [],
        responsible_doctor: [],
        photo_name: "",
        notes:"",
        isResetImg: false,
        province_id:null,
        capital_id:null,
      },
      // Other
      dataLoaded: false
    }
  },


  components: {
    Card,
    Form
  },

  methods: {
    async get() {
      this.form = await module.get('patients/' + this.$route.params.id)

      if (this.form == null) {
        this.$route.push('/patient/list')
      } else {
        this.form.photo_name = this.form.photo
        this.form.photo = []
        this.form.photo_default = "/assets/public/default-profile.svg",
        // this.form.gender = this.form.gender == "male" ? "Laki-laki" : "Perempuan"
        this.form['_method'] = "PUT"
        this.dataLoaded = true
      }
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pasien", route: "" },
      { title: "Daftar Pasien", route: "/patient/list" },
      { title: "Edit" },
    ])
    this.get()
  },
}

</script>
